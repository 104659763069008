import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './routes/App';
import { createBrowserRouter, RouterProvider, } from "react-router-dom";
import Home from './components/Home';
import Profile from './routes/profile/Profile';
import Login from './routes/login/Login';
import SignUp from './routes/signup/SignUp';
import BussinessSignUp from './routes/signup/BussinessSignUp';
import Search from './routes/search/Search';
import Company from './routes/company/Company';
const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            { index: true, element: <Home /> },
            {path:"profile/:userId", element:<Profile/>},
            {path:"/login", element:<Login/>},
            {path:"/signup", element:<SignUp/>},
            {path:"/bussinessSignUp", element:<BussinessSignUp/>},
            {path:"/search", element:<Search/>},
            {path:"/company/:itemId", element:<Company/>}
        ]
    },
]);
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <RouterProvider router={router}/>
    </React.StrictMode>
);
